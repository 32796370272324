(function($) {

  // based on https://css-tricks.com/simple-social-sharing-links/

  const socialWindow = url => {
    const left = (screen.width - 570) / 2;
    const top = (screen.height - 570) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
    window.open(url, 'NewWindow', params);
  };

  const pageUrl = encodeURIComponent(document.URL);

  const titleTag = document.head.querySelector('title');
  const title =
    titleTag && titleTag.textContent
      ? encodeURIComponent(titleTag.textContent)
      : '';

  document.querySelectorAll('.social-share').forEach(el => {
    if (el.matches('.facebook')) {
      el.addEventListener('click', () => socialWindow(`https://www.facebook.com/sharer.php?u=${pageUrl}`));
    }

    else if (el.matches('.twitter')) {
      el.addEventListener('click', () => socialWindow(`https://twitter.com/intent/tweet?url=${pageUrl}&text=${title}`));
    }

    else if (el.matches('.linkedin')) {
      el.addEventListener('click', () => socialWindow(`https://www.linkedin.com/shareArticle?url=${pageUrl}`));
    }

    else if (el.matches ('.email')) {
      el.href = `mailto:?${`body=${pageUrl}`}${title ? `&subject=${title}` : ''}`;
    }
  });

})(jQuery);